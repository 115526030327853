import "../../index22.css";
import { jsx as r, jsxs as I } from "react/jsx-runtime";
import { useMemo as le } from "react";
import { i as de } from "../../type-BFTbooL7.js";
import { _ as t } from "../../cs-piwRuCFZ.js";
import ce from "../DefaultMessage/index.js";
import me from "../FilterBar/index.js";
import fe from "../Footer/index.js";
import he from "../Header/index.js";
import h from "../../hooks/useSpacingTokens.js";
const pe = "_container_lyve2_1", Ce = "_header_lyve2_8", _e = "_centered-header-description-container_lyve2_11", ue = "_centered-description_lyve2_16", ge = "_with-border-and-header-background_lyve2_23", we = "_border_lyve2_33", ve = "_content_lyve2_39", Ne = "_pending-overlaid_lyve2_42", ye = "_message_lyve2_47", De = "_footer_lyve2_53", He = "_with-grid-view_lyve2_57", Be = "_two-columns_lyve2_67", ke = "_three-columns_lyve2_82", Ve = "_four-columns_lyve2_96", Ie = "_five-columns_lyve2_100", Me = "_with-vertical-view_lyve2_105", be = "_with-overflow-in-content_lyve2_112", e = {
  container: pe,
  header: Ce,
  centeredHeaderDescriptionContainer: _e,
  centeredDescription: ue,
  withBorderAndHeaderBackground: ge,
  border: we,
  content: ve,
  pendingOverlaid: Ne,
  message: ye,
  footer: De,
  withGridView: He,
  twoColumns: Be,
  threeColumns: ke,
  fourColumns: Ve,
  fiveColumns: Ie,
  withVerticalView: Me,
  withOverflowInContent: be
}, Ae = {
  2: e.twoColumns,
  3: e.threeColumns,
  4: e.fourColumns,
  5: e.fiveColumns
};
function ze(M) {
  const {
    actions: w,
    actionsContainerClassName: b,
    children: p,
    childrenContainerClassName: A,
    className: O,
    containerRef: T,
    contentViewType: l = "default",
    ellipsizeHeading: F,
    filters: v,
    filterActions: P,
    footerActions: N,
    footerActionsContainerClassName: G,
    footerClassName: S,
    footerContent: y,
    footerContentClassName: x,
    footerIcons: D,
    headerClassName: R,
    headerDescription: i,
    withCenteredHeaderDescription: C,
    headerDescriptionContainerClassName: W,
    headerElementRef: j,
    heading: H,
    headingClassName: z,
    headingContainerClassName: E,
    headingDescription: B,
    headingDescriptionContainerClassName: L,
    headingLevel: q,
    headingSectionClassName: J,
    icons: k,
    iconsContainerClassName: K,
    numPreferredGridContentColumns: Q = 2,
    spacing: n = "default",
    withHeaderBorder: U = !1,
    withFooterBorder: X = !1,
    withBorderAndHeaderBackground: o = !1,
    withOverflowInContent: Y = !1,
    withInternalPadding: a = !1,
    withoutWrapInHeading: Z = !1,
    withoutWrapInFooter: $ = !1,
    pending: s = !1,
    overlayPending: _ = !1,
    empty: d = !1,
    errored: c = !1,
    filtered: u = !1,
    compactMessage: ee = !1,
    errorMessage: ne,
    emptyMessage: oe,
    pendingMessage: re,
    filteredEmptyMessage: te
  } = M, g = D || y || N, V = H || w || k || i || B, ie = h({ spacing: n }), m = h({
    spacing: n,
    mode: "padding-h"
  }), f = h({
    spacing: n,
    mode: "padding-v"
  }), ae = h({
    spacing: n,
    mode: "gap",
    inner: !0
  }), se = le(
    () => de(i) ? null : C ? /* @__PURE__ */ r("div", { className: e.centeredDescription, children: i }) : i,
    [i, C]
  );
  return !V && !v && !p && !g && !d && !s && !c && !u ? null : /* @__PURE__ */ I(
    "div",
    {
      ref: T,
      className: t(
        e.container,
        !o && ie,
        a && f,
        Y && e.withOverflowInContent,
        o && e.withBorderAndHeaderBackground,
        l === "grid" && e.withGridView,
        l === "grid" && Ae[Q],
        l === "vertical" && e.withVerticalView,
        O
      ),
      children: [
        V && /* @__PURE__ */ r(
          he,
          {
            actions: w,
            className: t(
              e.header,
              o && f,
              (a || o) && m,
              R
            ),
            elementRef: j,
            actionsContainerClassName: b,
            ellipsizeHeading: F,
            heading: H,
            headingLevel: q,
            icons: k,
            iconsContainerClassName: K,
            childrenContainerClassName: t(
              C && e.centeredHeaderDescriptionContainer,
              W
            ),
            headingSectionClassName: J,
            headingClassName: z,
            headingContainerClassName: E,
            wrapHeadingContent: !Z,
            headingDescription: B,
            headingDescriptionContainerClassName: L,
            spacing: n,
            children: se
          }
        ),
        U && /* @__PURE__ */ r("div", { className: e.border }),
        /* @__PURE__ */ r(
          me,
          {
            filters: v,
            filterActions: P,
            spacing: n,
            className: t(a && m)
          }
        ),
        (p || d || s || c || u) && /* @__PURE__ */ I(
          "div",
          {
            className: t(
              e.content,
              l !== "default" && ae,
              (a || o) && m,
              o && f,
              _ && s && e.pendingOverlaid,
              A
            ),
            children: [
              /* @__PURE__ */ r(
                ce,
                {
                  className: e.message,
                  pending: s,
                  filtered: u,
                  errored: c,
                  empty: d,
                  compact: ee,
                  overlayPending: _,
                  emptyMessage: oe,
                  filteredEmptyMessage: te,
                  pendingMessage: re,
                  errorMessage: ne
                }
              ),
              !d && !c && (!s || _) && p
            ]
          }
        ),
        g && X && /* @__PURE__ */ r("div", { className: e.border }),
        g && /* @__PURE__ */ r(
          fe,
          {
            actions: N,
            icons: D,
            childrenContainerClassName: x,
            className: t(
              e.footer,
              a && m,
              o && f,
              S
            ),
            actionsContainerClassName: G,
            spacing: n,
            withoutWrap: $,
            children: y
          }
        )
      ]
    }
  );
}
export {
  ze as default
};
