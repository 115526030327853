import "../../index69.css";
import { jsxs as p, jsx as n } from "react/jsx-runtime";
import { i, a as _ } from "../../type-BFTbooL7.js";
import { _ as d } from "../../cs-piwRuCFZ.js";
import f from "../../hooks/useSpacingTokens.js";
const h = "_filter-bar_16xsj_1", u = "_grid-with-actions_16xsj_6", v = "_grid_16xsj_6", x = "_actions_16xsj_15", e = {
  filterBar: h,
  gridWithActions: u,
  grid: v,
  actions: x
};
function m(l) {
  const {
    grid: o,
    actions: s,
    spacing: r = "default"
  } = l, a = f({
    spacing: r,
    mode: "gap"
  }), c = f({
    spacing: r,
    mode: "gap",
    inner: !0
  }), t = f({
    spacing: r,
    mode: "grid-gap"
  });
  return i(o) && i(s) ? null : /* @__PURE__ */ p("div", { className: d(e.gridWithActions, a), children: [
    /* @__PURE__ */ n("div", { className: d(e.grid, t), children: o }),
    _(s) && /* @__PURE__ */ n("div", { className: d(e.actions, c), children: s })
  ] });
}
function T(l) {
  const {
    className: o,
    filters: s,
    filterActions: r,
    search: a,
    searchActions: c,
    filterPreview: t,
    spacing: g
  } = l;
  return i(s) && i(r) && i(a) && i(c) && i(t) ? null : /* @__PURE__ */ p("div", { className: d(e.filterBar, o), children: [
    /* @__PURE__ */ n(
      m,
      {
        grid: s,
        actions: r,
        spacing: g
      }
    ),
    /* @__PURE__ */ n(
      m,
      {
        grid: a,
        actions: c,
        spacing: g
      }
    ),
    _(t) && /* @__PURE__ */ n("div", { className: e.filterPreview, children: t })
  ] });
}
export {
  T as default
};
