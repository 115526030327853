import "../../index85.css";
import { jsxs as S, Fragment as L, jsx as e } from "react/jsx-runtime";
import { useRef as m, useCallback as i } from "react";
import { CheckDoubleFillIcon as be, CloseLineIcon as ve, ArrowUpSmallFillIcon as we, ArrowDownSmallFillIcon as _e } from "@ifrc-go/icons";
import { b as ke } from "../../type-BFTbooL7.js";
import { _ as W } from "../../cs-piwRuCFZ.js";
import b from "../Button/index.js";
import Fe from "../InputContainer/index.js";
import Re from "../List/index.js";
import Te from "../Popup/index.js";
import Pe from "../RawInput/index.js";
import Oe from "../../hooks/useBlurEffect.js";
import De from "../../hooks/useKeyboard.js";
import Ee from "../../hooks/useTranslation.js";
import je from "./GenericOption/index.js";
const Ae = "common", Ke = {
  infoMessageAnd: "and",
  infoMessageMore: "more",
  buttonTitleSelect: "Select all",
  buttonTitleClear: "Clear",
  buttonTitleClose: "Close",
  buttonTitleOpen: "Open",
  selectInputPendingMessage: "Fetching options...",
  selectInputEmptyMessage: "No option available",
  selectInputFilteredMessage: "No option available for the search",
  selectInputErrorMessage: "Could not load options"
}, Be = {
  namespace: Ae,
  strings: Ke
}, xe = "_popup_poyjv_1", Le = "_list_poyjv_4", We = "_list-item_poyjv_9", $e = "_info-message_poyjv_14", He = "_icon_poyjv_21", r = {
  popup: xe,
  list: Le,
  listItem: We,
  infoMessage: $e,
  icon: He
}, qe = [];
function ao($) {
  const {
    actions: H,
    actionsContainerClassName: q,
    className: G,
    disabled: p,
    error: U,
    errorContainerClassName: V,
    hint: z,
    hintContainerClassName: J,
    icons: Q,
    iconsContainerClassName: X,
    inputSectionClassName: Y,
    label: Z,
    labelClassName: ee,
    name: d,
    onOptionClick: v,
    searchText: oe,
    onSearchTextChange: w,
    optionContainerClassName: _,
    optionKeySelector: k,
    optionRenderer: F,
    optionRendererParams: R,
    options: ne,
    optionsPopupClassName: te,
    persistentOptionPopup: u,
    readOnly: a,
    placeholder: se,
    valueDisplay: f = "",
    nonClearable: ie,
    onClearButtonClick: re,
    onSelectAllButtonClick: T,
    optionsPending: P = !1,
    optionsFiltered: ae = !1,
    optionsErrored: O = !1,
    focused: le,
    focusedKey: C,
    onFocusedKeyChange: h,
    onFocusedChange: D,
    dropdownShown: o,
    onDropdownShownChange: t,
    totalOptionsCount: E = 0,
    hasValue: ce,
    autoFocus: pe,
    onEnterWithoutOption: ue,
    withAsterisk: me,
    required: de,
    variant: fe,
    errorOnTooltip: Ce,
    dropdownHidden: he
  } = $, g = ne ?? qe, n = Ee(Be), j = m(null), A = m(null), y = m(null), K = m(null), ge = i(
    (s) => {
      o || t(!0), w(s);
    },
    [
      o,
      t,
      w
    ]
  ), ye = i(
    (s, l) => {
      l.stopPropagation(), t(!o);
    },
    [o, t]
  ), M = i(
    () => {
      o || t(!0);
    },
    [
      o,
      t
    ]
  ), c = i(
    () => {
      t(!1);
    },
    [t]
  ), Me = i(
    () => {
      a || M();
    },
    [a, M]
  ), Ie = i(
    (s, l) => {
      s || l ? u && y.current && y.current.focus() : c();
    },
    [c, u]
  ), I = i(
    (s, l) => {
      v(s, l, d), u || c();
    },
    [v, c, u, d]
  ), Ne = i(
    (s, l) => ({
      contentRendererParam: R,
      option: l,
      optionKey: s,
      focusedKey: C,
      contentRenderer: F,
      onClick: I,
      onFocus: h,
      optionContainerClassName: W(_, r.listItem)
    }),
    [
      C,
      I,
      h,
      _,
      F,
      R
    ]
  );
  Oe(
    o,
    Ie,
    K,
    j
  );
  const Se = De(
    C,
    k,
    g,
    o,
    h,
    c,
    M,
    I,
    ue
  ), B = g.length, x = E - B > 0 ? `${n.infoMessageAnd} ${E - B} ${n.infoMessageMore}` : void 0, N = o && !he;
  return /* @__PURE__ */ S(L, { children: [
    /* @__PURE__ */ e(
      Fe,
      {
        actionsContainerClassName: q,
        className: G,
        containerRef: j,
        disabled: p,
        errorContainerClassName: V,
        error: U,
        errorOnTooltip: Ce,
        hintContainerClassName: J,
        hint: z,
        iconsContainerClassName: X,
        icons: Q,
        inputSectionClassName: Y,
        inputSectionRef: A,
        labelClassName: ee,
        label: Z,
        readOnly: a,
        required: de,
        variant: fe,
        withAsterisk: me,
        actions: /* @__PURE__ */ S(L, { children: [
          H,
          !a && T && /* @__PURE__ */ e(
            b,
            {
              onClick: T,
              disabled: p,
              variant: "tertiary",
              name: void 0,
              title: n.buttonTitleSelect,
              children: /* @__PURE__ */ e(be, { className: r.icon })
            }
          ),
          !a && !ie && ce && /* @__PURE__ */ e(
            b,
            {
              onClick: re,
              disabled: p,
              variant: "tertiary",
              name: void 0,
              title: n.buttonTitleClear,
              children: /* @__PURE__ */ e(ve, { className: r.icon })
            }
          ),
          !a && /* @__PURE__ */ e(
            b,
            {
              onClick: ye,
              variant: "tertiary",
              name: void 0,
              title: N ? n.buttonTitleClose : n.buttonTitleOpen,
              children: N ? /* @__PURE__ */ e(we, { className: r.icon }) : /* @__PURE__ */ e(_e, { className: r.icon })
            }
          )
        ] }),
        input: /* @__PURE__ */ e(
          Pe,
          {
            name: d,
            elementRef: y,
            readOnly: a,
            disabled: p,
            value: o || le ? oe : f,
            onChange: ge,
            onClick: Me,
            onFocus: () => D(!0),
            onBlur: () => D(!1),
            placeholder: ke(f) ? f : se,
            autoComplete: "off",
            onKeyDown: Se,
            autoFocus: pe
          }
        )
      }
    ),
    N && /* @__PURE__ */ S(
      Te,
      {
        elementRef: K,
        parentRef: A,
        className: W(te, r.popup),
        children: [
          /* @__PURE__ */ e(
            Re,
            {
              className: r.list,
              data: g,
              keySelector: k,
              renderer: je,
              rendererParams: Ne,
              errored: O,
              filtered: ae,
              pending: P,
              pendingMessage: n.selectInputPendingMessage,
              emptyMessage: n.selectInputEmptyMessage,
              filteredEmptyMessage: n.selectInputFilteredMessage,
              errorMessage: n.selectInputErrorMessage,
              compact: !0
            }
          ),
          !P && !O && !!x && /* @__PURE__ */ e("div", { className: r.infoMessage, children: x })
        ]
      }
    )
  ] });
}
export {
  ao as default
};
